import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import NavBar from '../Navbar';
import ScrollToTop from '../../components/ScrollToTop';
import FormInput from '../../layouts/FormInput';
import SelectUI from '../../layouts/Select';

import { tips } from '../../constants/tips';
import { UserContext } from '../../components/UserContext';

//styles
import styles from '../../styles/donations.module.css';
import Accordion from '../../layouts/Accordion';
import StripeCheckoutForm from './CustomStripeForm';
import { FaAngleLeft } from 'react-icons/fa';

const PaymentOptions = (props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('credit_card');

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
  };

  const paymentOptions = [
    { id: 'google_pay', label: 'Google Pay' },
    { id: 'apple_pay', label: 'Apple Pay' },
    { id: 'paypal', label: 'PayPal' },
    { id: 'credit_card', label: 'Credit/Debit Card' },
  ];

  let submissionObject = {};
  const storedSubmission = localStorage.getItem('donationSubmission');
  if (storedSubmission) {
    submissionObject = JSON.parse(storedSubmission);
    console.log(submissionObject); // This will log the stored object
  } else {
    console.log('No submission data found in local storage');
  }

  const charityName = submissionObject.charityName;
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location);

  const goBack = () => {
    navigate(-1);
  };
  const context = useContext(UserContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    // navigate('/make/payment/id');
  };
  console.log(errors);

  return (
    <>
      <NavBar />
      <div className={styles.container}>
        <div className={styles.back_btn_container}>
          <FaAngleLeft className={styles.back_btn} onClick={goBack} size={35} />

          <p className={styles.title}>Payment Method</p>
        </div>
        <div className={styles.user}>
          <img className={styles.photo} src={context.photoUrl} alt='' />
          <p className={styles.userName}>
            <span className={styles.charityName}>
              {submissionObject.charityName}
            </span>
            {context.displayName}
          </p>
        </div>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <br />
        <ScrollToTop />
        <div className={styles.formsContainer}>
          <Accordion
            paymentOptions={paymentOptions}
            onPaymentMethodChange={handlePaymentMethodChange}
          />
          {selectedPaymentMethod === 'credit_card' && (
            <div className={styles.creditCardForm}>
              {/* Render your form inputs here */}
              <StripeCheckoutForm
                amount={submissionObject.money}
                productName={submissionObject.movementId}
                charity={submissionObject.charityName}
                userId={context.userID || 'anonymous user'}
                anonymousUser={!context.userID}
              />
            </div>
          )}
        </div>
        <br />
      </div>
    </>
  );
};

export default PaymentOptions;
