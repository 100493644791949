// StripeCheckoutForm.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  createPaymentIntentOnFirebase,
  fetchPaymentIntentDetails,
} from '../../config/FirbaseFunctions';
import styles from '../../styles/stripe.module.css';

const CARD_ELEMENT_STYLES = {
  style: {
    base: {
      iconColor: '#666EE8',
      color: '#31325F',
      fontWeight: 400,
      // fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      // fontSize: '16px',
      '::placeholder': {
        color: '#CFD7E0',
      },
      ':-webkit-autofill': {
        color: '#fce883',
      },
    },
    invalid: {
      color: '#E25950',
    },
  },
};

const StripeCheckoutForm = ({
  amount,
  productName,
  charity,
  userId,
  anonymousUser,
}) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState({
    line1: '',
    city: '',
    postal_code: '',
    country: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddressChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return; // Stripe.js has not loaded yet
    }

    setLoading(true);
    setError('');

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const billingDetails = {
      name,
      email,
      address,
    };

    try {
      const { clientSecret } = await createPaymentIntentOnFirebase({
        amount: amount,
        billingDetails,
        productName,
        charity,
        userId,
        anonymousUser,
      });

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          type: 'card',
          card: cardNumberElement,
          billing_details: {
            name: name,
            email: email,
            address: {
              line1: address.line1,
              city: address.city,
              postal_code: postalCode, // Use the single source of postal code here
              country: address.country,
            },
          },
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else if (result.paymentIntent.status === 'succeeded') {
        try {
          const paymentIntentDetails = await fetchPaymentIntentDetails(
            result.paymentIntent.id
          );
          console.log('PaymentIntent Details:', paymentIntentDetails);

          const movementId = paymentIntentDetails.metadata.movementId;
          console.log('Movement ID:', movementId);
          navigate(`/success?movementId=${movementId}`);
        } catch (error) {
          setError('Error fetching payment details');
        }
      }
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.stripeForm}>
      <input
        className={styles.cardNumber}
        type='text'
        placeholder='Name'
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        className={styles.cardNumber}
        type='email'
        placeholder='Email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        className={styles.cardNumber}
        type='text'
        placeholder='Address Line 1'
        name='line1'
        value={address.line1}
        onChange={handleAddressChange}
      />
      <input
        className={styles.cardNumber}
        type='text'
        placeholder='City'
        name='city'
        value={address.city}
        onChange={handleAddressChange}
      />
      <input
        className={styles.cardNumber}
        type='text'
        placeholder='Postal Code'
        name='postal_code'
        value={address.postal_code}
        onChange={handleAddressChange}
      />
      <input
        className={styles.cardNumber}
        type='text'
        placeholder='Country'
        name='country'
        value={address.country}
        onChange={handleAddressChange}
      />
      <div className={styles.cardNumber}>
        <label htmlFor='cardNumber'>Card Number</label>
        <CardNumberElement id='cardNumber' options={CARD_ELEMENT_STYLES} />
      </div>

      <div className={styles.cardNumber}>
        <label htmlFor='cardExpiry'>Expiration Date</label>
        <CardExpiryElement id='cardExpiry' options={CARD_ELEMENT_STYLES} />
      </div>
      <div className={styles.cardNumber}>
        <label htmlFor='cardCvc'>CVC</label>
        <CardCvcElement id='cardCvc' options={CARD_ELEMENT_STYLES} />
      </div>
      <input
        className={styles.cardNumber}
        id='postalCode'
        type='text'
        placeholder='Postal Code'
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value.toUpperCase())} // Convert to upper case for consistency
        maxLength='10' // Canadian postal codes are 7 characters including the space
        title='Postal Code (e.g.  33125)'
        required
      />

      <button
        type='submit'
        disabled={!stripe || loading}
        className={styles.donateBtn}
      >
        {loading ? 'Processing…' : 'Donate Now'}
      </button>
      {error && <div className='error'>{error}</div>}
    </form>
  );
};

export default StripeCheckoutForm;
